<template>
  <div class="card">
    <b-card :title="this.$route.meta.title">
      <b-row>
        <b-col md="2" class="text-left">
          <b-row>
            <b-col md="12">
              <b-media
                class="d-flex justify-content-center mb-0 flex-wrap"
                no-body
              >
                <b-media-aside>
                  <b-avatar
                    :src="model.logo"
                    class="mb-1"
                    size="90px"
                  ></b-avatar>
                </b-media-aside>
              </b-media>
            </b-col>
            <b-col md="2"> </b-col>
            <b-col md="10">
              <b-badge v-if="model.logo" variant="light-primary" class="mb-1">
                {{ model.statuses.logo }}
              </b-badge>
              <p v-if="model.logo">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  v-if="model.statuses.logo == 'PENDING'"
                  variant="primary"
                  @click="approves('logo')"
                  class="btn-icon rounded-circle mr-1"
                >
                  <feather-icon icon="CheckIcon" />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="danger"
                  class="btn-icon rounded-circle"
                  v-if="model.statuses.logo == 'PENDING'"
                  @click="reject('logo')"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="10" class="mt-1">
          <!-- <b-row>
            <b-col md="3">
              <p
                class="p-1 text-center text-capotalize font-weight-bolder"
                style="border: 2px solid #333; border-radius: 5px"
              >
                {{ model.status }}
              </p>
            </b-col>
          </b-row> -->
          <b-row>
            <b-col md="12">
              <p class="mb-0"><b>Compnay Name</b></p>
              <p class="mb-2">
                {{ model.name }}
              </p>
            </b-col>

            <b-col md="6">
              <p class="mb-0"><b>Contributor Name</b></p>
              <p class="mb-2">
                {{
                  model.account.profile.firstName +
                  " " +
                  model.account.profile.lastName
                }}
              </p>
            </b-col>

            <b-col md="6" class="mb-2">
              <p class="mb-0"><b>Contributor As</b></p>
              <p class="mb-2" v-if="model.contributorType == 'EMPLOYEE'">
                Employee
              </p>
              <p class="mb-2" v-if="model.contributorType == 'OWNER'">Owner</p>
              <p class="mb-2" v-if="model.contributorType == 'EXTERNAL'">
                User have information
              </p>
            </b-col>

            <b-col md="6" class="">
              <p class="mb-0"><b>Website</b></p>
              <p>
                {{ model.website }}

                <b-badge
                  v-if="model.website"
                  variant="light-primary"
                  class="ml-1"
                >
                  {{ model.statuses.website }}
                </b-badge>
              </p>

              <p v-if="model.website">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  v-if="model.statuses.website == 'PENDING'"
                  class="mr-1"
                  variant="primary"
                  @click="approves('website')"
                >
                  Approve
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="danger"
                  v-if="model.statuses.website == 'PENDING'"
                  @click="reject('website')"
                >
                  Reject
                </b-button>
              </p>
            </b-col>

            <b-col md="6">
              <p class="mb-0"><b>Phone Number</b></p>
              <p>
                {{ model.phoneNumber }}
                <b-badge
                  v-if="model.phoneNumber"
                  variant="light-primary"
                  class="ml-1"
                >
                  {{ model.statuses.phoneNumber }}
                </b-badge>
              </p>

              <p v-if="model.phoneNumber">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  v-if="model.statuses.phoneNumber == 'PENDING'"
                  class="mr-1"
                  variant="primary"
                  @click="approves('phone')"
                >
                  Approve
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="danger"
                  v-if="model.statuses.phoneNumber == 'PENDING'"
                  @click="reject('phone')"
                >
                  Reject
                </b-button>
              </p>
            </b-col>

            <b-col md="6">
              <p class="mb-0"><b>Email</b></p>
              <p>
                {{ model.email }}

                <b-badge
                  v-if="model.email"
                  variant="light-primary"
                  class="ml-1"
                >
                  {{ model.statuses.email }}
                </b-badge>
              </p>

              <p v-if="model.email">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  v-if="model.statuses.email == 'PENDING'"
                  class="mr-1"
                  variant="primary"
                  @click="approves('email')"
                >
                  Approve
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="danger"
                  v-if="model.statuses.email == 'PENDING'"
                  @click="reject('email')"
                >
                  Reject
                </b-button>
              </p>
            </b-col>

            <!-- <b-col md="6">
              <p class="mb-0"><b>Certificate</b></p>
              <b-link :href="model.certificate" target="_blank">
                <span>
                  {{ model.certificate }}
                  <b-badge variant="light-primary" class="ml-1">
                    {{ model.statuses.certificate }}
                  </b-badge>
                </span>
              </b-link>
              <p class="mt-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  v-if="model.statuses.certificate == 'PENDING'"
                  class="mr-1"
                  variant="primary"
                  @click="approves('cert')"
                >
                  Approve
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="danger"
                  v-if="model.statuses.certificate == 'PENDING'"
                  @click="reject('cert')"
                >
                  Reject
                </b-button>
              </p>
            </b-col> -->
            <b-col md="6">
              <p class="mb-0"><b>Industries</b></p>
              <p>
                {{ model.industry ? model.industry.name : "" }}

                <b-badge
                  v-if="model.industry"
                  variant="light-primary"
                  class="ml-1"
                >
                  {{ model.statuses.industryId }}
                </b-badge>
              </p>

              <p v-if="model.industry">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  v-if="model.statuses.industryId == 'PENDING'"
                  class="mr-1"
                  variant="primary"
                  @click="approves('industryId')"
                >
                  Approve
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="danger"
                  v-if="model.statuses.industryId == 'PENDING'"
                  @click="reject('industryId')"
                >
                  Reject
                </b-button>
              </p>
            </b-col>

            <b-col md="6">
              <p class="mb-0"><b>Description</b></p>
              <p>
                {{ model.description }}

                <b-badge
                  v-if="model.description"
                  variant="light-primary"
                  class="ml-1"
                >
                  {{ model.statuses.description }}
                </b-badge>
              </p>

              <p v-if="model.description">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  v-if="model.statuses.description == 'PENDING'"
                  class="mr-1"
                  variant="primary"
                  @click="approves('desc')"
                >
                  Approve
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="danger"
                  v-if="model.statuses.description == 'PENDING'"
                  @click="reject('desc')"
                >
                  Reject
                </b-button>
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card-footer>
      <!-- Back -->
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="reset"
        class="mr-1 float-right"
        variant="outline-danger"
        :to="{ name: 'contributor' }"
      >
        Back
      </b-button>
    </b-card-footer>
  </div>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BLink,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BTab,
  BTabs,
  BModal,
  VBModal,
  BAvatar,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BLink,
    BImg,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    vSelect,
    BTab,
    BTabs,
    BModal,
    BAvatar,
    BBadge,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
    "b-modal": VBModal,
  },
  data() {
    return {
      name: "InfluencerForms",
      errors: {},
      config: {
        api: "contributions",
      },
      model: {
        statuses: {},
      },
    };
  },
  beforeMount() {
    let _ = this;
    _.getData();
  },
  methods: {
    approves(data) {
      let data_post = {};
      if (data == "name") {
        data_post = {
          statuses: {
            cert: this.model.statuses.cert,
            name: "APPROVED",
            email: this.model.statuses.email,
            website: this.model.statuses.website,
            description: this.model.statuses.description,
            phoneNumber: this.model.statuses.phoneNumber,
            logo: this.model.statuses.logo,
            industryId: this.model.statuses.industryId,
          },
        };
      } else if (data == "phone") {
        data_post = {
          statuses: {
            cert: this.model.statuses.cert,
            name: this.model.statuses.name,
            email: this.model.statuses.email,
            website: this.model.statuses.website,
            description: this.model.statuses.description,
            phoneNumber: "APPROVED",
            logo: this.model.statuses.logo,
            industryId: this.model.statuses.industryId,
          },
        };
      } else if (data == "email") {
        data_post = {
          statuses: {
            cert: this.model.statuses.cert,
            name: this.model.statuses.name,
            email: "APPROVED",
            website: this.model.statuses.website,
            description: this.model.statuses.description,
            phoneNumber: this.model.statuses.phoneNumber,
            logo: this.model.statuses.logo,
            industryId: this.model.statuses.industryId,
          },
        };
      } else if (data == "cert") {
        data_post = {
          statuses: {
            cert: "APPROVED",
            name: this.model.statuses.name,
            email: this.model.statuses.email,
            website: this.model.statuses.website,
            description: this.model.statuses.description,
            phoneNumber: this.model.statuses.phoneNumber,
            logo: this.model.statuses.logo,
            industryId: this.model.statuses.industryId,
          },
        };
      } else if (data == "website") {
        data_post = {
          statuses: {
            cert: this.model.statuses.cert,
            name: this.model.statuses.name,
            email: this.model.statuses.email,
            website: "APPROVED",
            description: this.model.statuses.description,
            phoneNumber: this.model.statuses.phoneNumber,
            logo: this.model.statuses.logo,
            industryId: this.model.statuses.industryId,
          },
        };
      } else if (data == "desc") {
        data_post = {
          statuses: {
            cert: this.model.statuses.cert,
            name: this.model.statuses.name,
            email: this.model.statuses.email,
            website: this.model.statuses.website,
            description: "APPROVED",
            phoneNumber: this.model.statuses.phoneNumber,
            logo: this.model.statuses.logo,
            industryId: this.model.statuses.industryId,
          },
        };
      } else if (data == "logo") {
        data_post = {
          statuses: {
            cert: this.model.statuses.cert,
            name: this.model.statuses.name,
            email: this.model.statuses.email,
            website: this.model.statuses.website,
            description: this.model.statuses.description,
            phoneNumber: this.model.statuses.phoneNumber,
            logo: "APPROVED",
            industryId: this.model.statuses.industryId,
          },
        };
      } else if (data == "industryId") {
        data_post = {
          statuses: {
            cert: this.model.statuses.cert,
            name: this.model.statuses.name,
            email: this.model.statuses.email,
            website: this.model.statuses.website,
            description: this.model.statuses.description,
            phoneNumber: this.model.statuses.phoneNumber,
            logo: this.model.statuses.logo,
            industryId: "APPROVED",
          },
        };
      }
      console.log(data_post);
      this.$swal({
        title: "Are you sure ?",
        text: "want to approve this data  " + data + "?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .patch(
              "https://ch-api.demoapp.xyz/v3/contributions/" +
                this.$route.params.id,
              data_post
            )
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Success Approve",
                text: "Data has been approved",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.getData();
            })
            .catch((err) => {
              this.$swal({
                icon: "Danger",
                title: "",
                text: err.response.data.message,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },

    reject(data) {
      let data_post = {};
      if (data == "name") {
        data_post = {
          statuses: {
            cert: this.model.statuses.cert,
            name: "REJECTED",
            email: this.model.statuses.email,
            website: this.model.statuses.website,
            description: this.model.statuses.description,
            phoneNumber: this.model.statuses.phoneNumber,
            logo: this.model.statuses.logo,
            industryId: this.model.statuses.industryId,
          },
        };
      } else if (data == "phone") {
        data_post = {
          statuses: {
            cert: this.model.statuses.cert,
            name: this.model.statuses.name,
            email: this.model.statuses.email,
            website: this.model.statuses.website,
            description: this.model.statuses.description,
            phoneNumber: "REJECTED",
            logo: this.model.statuses.logo,
            industryId: this.model.statuses.industryId,
          },
        };
      } else if (data == "email") {
        data_post = {
          statuses: {
            cert: this.model.statuses.cert,
            name: this.model.statuses.name,
            email: "REJECTED",
            website: this.model.statuses.website,
            description: this.model.statuses.description,
            phoneNumber: this.model.statuses.phoneNumber,
            logo: this.model.statuses.logo,
            industryId: this.model.statuses.industryId,
          },
        };
      } else if (data == "cert") {
        data_post = {
          statuses: {
            cert: "REJECTED",
            name: this.model.statuses.name,
            email: this.model.statuses.email,
            website: this.model.statuses.website,
            description: this.model.statuses.description,
            phoneNumber: this.model.statuses.phoneNumber,
            logo: this.model.statuses.logo,
            industryId: this.model.statuses.industryId,
          },
        };
      } else if (data == "website") {
        data_post = {
          statuses: {
            cert: this.model.statuses.cert,
            name: this.model.statuses.name,
            email: this.model.statuses.email,
            website: "REJECTED",
            description: this.model.statuses.description,
            phoneNumber: this.model.statuses.phoneNumber,
            logo: this.model.statuses.logo,
            industryId: this.model.statuses.industryId,
          },
        };
      } else if (data == "desc") {
        data_post = {
          statuses: {
            cert: this.model.statuses.cert,
            name: this.model.statuses.name,
            email: this.model.statuses.email,
            website: this.model.statuses.website,
            description: "REJECTED",
            phoneNumber: this.model.statuses.phoneNumber,
            logo: this.model.statuses.logo,
            industryId: this.model.statuses.industryId,
          },
        };
      } else if (data == "logo") {
        data_post = {
          statuses: {
            cert: this.model.statuses.cert,
            name: this.model.statuses.name,
            email: this.model.statuses.email,
            website: this.model.statuses.website,
            description: this.model.statuses.description,
            phoneNumber: this.model.statuses.phoneNumber,
            logo: "REJECTED",
            industryId: this.model.statuses.industryId,
          },
        };
      } else if (data == "industryId") {
        data_post = {
          statuses: {
            cert: this.model.statuses.cert,
            name: this.model.statuses.name,
            email: this.model.statuses.email,
            website: this.model.statuses.website,
            description: this.model.statuses.description,
            phoneNumber: this.model.statuses.phoneNumber,
            logo: this.model.statuses.logo,
            industryId: "REJECTED",
          },
        };
      }
      console.log(data_post);
      this.$swal({
        title: "Are you sure ?",
        text: "want to Reject this data  " + data + "?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .patch(
              "https://ch-api.demoapp.xyz/v3/contributions/" +
                this.$route.params.id,
              data_post
            )
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Success Reject",
                text: "Data has been Rejected",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.getData();
            })
            .catch((err) => {
              this.$swal({
                icon: "Danger",
                title: "",
                text: err.response.data.message,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },
    getData() {
      let _ = this;
      axios.get(_.config.api + "/" + this.$route.params.id).then((response) => {
        let _ = this;
        _.model = response.data;
      });
    },
  },
};
</script>
